/*
        This mixin is used to enable inline editing for hardcoded fields not picked up by the standard setup.
        This can for instance be if you hardcode components right into the vue page template.
        Or if your populating a component with data not found in the normal component list
        (like say you get it from the metadata).

        To use this mixin you will have to include it into your vue component,
        and setup your hardcoded elements with pointers to where the data is located in Gizmo/schema.

        This is done by including a data-schemaPath="POINTER TO SCHEMA DATA" on your html element.
        An example could look like this (remember to turn on v-editable-string for your hardcoded elements aswell):
        <div data-schemaPath="metadata.articleIngress" v-editable-string>{{metadata.articleIngress}}</div>

        If your just pushing strings to input fields in Gizmo you can specify the entire root path
        for data-schemaPath="" and it will try to push your data into this without trying anything smart.
        But sometimes you don't know exactly where what the user fills out or where the Gizmo data should be pushed.
        An example for this can be if your hardcoding in an entire reusable component that gets the data from like say metadata:
        <HeroBanner data-schemaPath="metadata." :component="articleHeroBanner" />

        Here you can se we have specified a . at the end to say to the visualizer that this is not the end value,
        it is an object and the value we want to change/push should be located under this object.
        The Gizmo vizualizer will handle the rest.

        If you want to see some more examples check:
        - pillarArticle.vue
        - tweArticle.vue
        - standardArticle.vue

        Debugging: To check that its targeting the correct schema props you should check in the console log editor
        when doing visual changes to make sure all the logging looks correct. You can also view the vuex page element in Gizmo
        to make sure its going towards the correct path, and not creating its own element.
         */

export default {
	computed: {
		dataPath() {
			let dataPath = "";
			if (this.$config.public.isPreview && this.$store.state.testIndex) {
				dataPath = "/abTestPages/" + this.$store.state.testIndex;
			}
			return dataPath;
		},
	},
	methods: {
		findDatasetSchemaPath(target, loop = 0) {
			if (target.dataset && target.dataset.schemapath) {
				return target.dataset.schemapath;
			} else if (target.parentNode && loop < 30) {
				// Stop at 30 HTML parents to try to find the schemaPath
				return this.findDatasetSchemaPath(target.parentNode);
			}
			return null;
		},
		eventClassPath(event) {
			if (event.path)
				return event.path
					.map((obj) => {
						return obj.className;
					})
					.join("");
			else return "";
		},
		handleHardcodeContentChanged(event) {
			event.stopPropagation();
			console.log("handleHardcodeContentChanged");
			if (this.$config.public.isPreview) {
				console.log("posting message", event.detail);

				let schemaPath = event && event.target ? this.findDatasetSchemaPath(event.target) : null;
				let detail = event.detail;
				if (schemaPath) {
					let unknownProp = false;
					schemaPath = (schemaPath && schemaPath.replace(/\./g, "/")) || "";
					if (!schemaPath.startsWith("/")) schemaPath = "/" + schemaPath;
					if (schemaPath.endsWith("/")) {
						schemaPath = schemaPath.slice(0, schemaPath.length - 1);
						unknownProp = true;
					} else if (this.eventClassPath(event).startsWith("editable-image") || schemaPath === "/metadata") {
						// Hardcode image change and metadata to be unknown prop to help developers if the forget to add the / at the end
						unknownProp = true;
						console.log("Hard coding unknownProp = true before sending values");
					}
					parent.postMessage(
						{
							action: "change",
							payload: {
								dataPath: this.dataPath + schemaPath,
								value: detail.value,
								oldValue: unknownProp && detail.oldValue ? detail.oldValue : false,
								unknownProp: unknownProp,
							},
						},
						"*",
					);
				} else {
					console.log(
						"Could not find related schemaPath for sending visual changes. Please add data-schemaPath= prop to your HTML Element",
					);
				}
			}
		},
	},
	mounted() {
		if (this.$config.public.isPreview) this.$el.addEventListener("contentChanged", this.handleHardcodeContentChanged);
	},
};
